import {
  Box,
  Button,
  Input,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import tz_lookup from 'tz-lookup';
import "./filterBetry.css";
import Divider from "@mui/material/Divider";

import { ChangeTimeIcon, FleshIcon } from "../../utils/Icons/Icons";
import { data } from "./filterdata";
import { client } from "./Socket";
import LoadingScreen from "./LoadingScreen";
import axios from "axios";
import MapContainer, { getAddressFromLatLng } from "./Maps";
import LocationTime from "../Nagivation";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { type } from "@testing-library/user-event/dist/type";

dayjs.extend(utc);
dayjs.extend(timezone);



export const sendData = (topic, value) => {
  client.publish(topic, value);
};



function parseTimestamp(timestamp) {
  try {
    const [datePart, timePart] = timestamp.split(" ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [hour, minute, second] = timePart.split(":").map(Number);

    return new Date(year, month - 1, day, hour, minute, second);
  } catch (e) {
    // console.log(e);
    return new Date();
  }
}

const OnlineIcon = ({ timestamp }) => {
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    const updateColor = () => {
      const currentTime =
        new Date().getTime() + new Date().getTimezoneOffset() * 60000;

      const date = parseTimestamp(timestamp);
      const lastHeartbeat = date.getTime();
      const timeDifference = (currentTime - lastHeartbeat) / 1000; // Convert to seconds

      const isOnline = timeDifference <= 10; // 10 seconds

      setIsOnline(isOnline);
      // console.log(
      //   "isOnline",
      //   isOnline,
      //   currentTime,
      //   timestamp,
      //   lastHeartbeat,
      //   timestamp,
      //   date
      // );
    };

    updateColor();
    const interval = setInterval(updateColor, 1000);

    return () => clearInterval(interval);
  }, [timestamp]);

  return (
    <Box
      className={`rounded-full w-10 h-10 ${
        isOnline ? "bg-green-600" : "bg-red-500"
      }`}
    ></Box>
  );
};

const isValidDate = (utcTimeString) => {
  const [date, time] = utcTimeString.split(" ");
  const [day, month, year] = date.split("/");
  const formattedUTCString = `${year}-${month}-${day}T${time}`;

  // Get timezone from coordinates using Google Time Zone API
  const timestamp = Math.floor(new Date(formattedUTCString).getTime() / 1000);
  if (isNaN(timestamp)) {
    return false;
  } else {
    return true;
  }
};
// export const getDateTimeUsingLocation = async (
//   latitude,
//   longitude,
//   utcTimeString
// ) => {
//   // Convert DD/MM/YYYY format to YYYY-MM-DD
//   console.log("utcTimeString", utcTimeString);
//   console.log("formattedUTCString", new Date(utcTimeString));

//   // Get timezone from coordinates using Google Time Zone API
//   const timestamp = Math.floor(new Date(utcTimeString).getTime() / 1000);
//   if (isNaN(timestamp)) {
//     return;
//   }
//   const response = await fetch(
//     `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=AIzaSyBajhVyi8KbuKuEPk1osx9M_7qmpg8e1-c`
//   );
//   const timezoneData = await response.json();

//   const utcDate = new Date(utcTimeString);
//   // Apply the timezone offset
//   const localTime = new Date(utcDate.getTime() + timezoneData.rawOffset * 1000);


// // time loggging@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@



//   console.log(
//     "Time Zone output",
//     localTime,
//     timestamp,
//     latitude,
//     longitude,
//     utcTimeString
//   );

//   return localTime.toLocaleString("en-US", {
//     year: "numeric",
//     month: "2-digit",
//     day: "2-digit",
//     hour: "2-digit",
//     minute: "2-digit",
//     second: "2-digit",
//     hour12: false,
//   });
// };




export default function FilterSubCom({ locationData, setEnabled }) {
  const [localState, setLocalState] = useState({});
  const [loading, setLoading] = useState(false);
  let configuration_list = [
    "current",
    "time_left",
    "time_on",
    "system_led",
    "filter_led",
    "water_led",
    "blocked_led",
    "system_id",
    "schedule_id",
    "percentage",
    "name",
    "high_thesold",
    "low_thesold",
    "timestamp",
    "filter",
    "enabled",
    "offset",
    "heartbeat",
    "is_notification_enabled"
  ];

  const initMap = {
    current: "current",
    time_left: "time_left",
    time_on: "time_on",
    system_led: "system_led",
    filter_led: "filter_led",
    water_led: "water_led",
    blocked_led: "blocked_led",
    system_id: "system_id",
    schedule_id: "schedule_id",
    percentage: "percentage",
    name: "name",
    high_thesold: "high_thesold",
    low_thesold: "low_thesold",
    timestamp: "timestamp",
    filter: "filter",
    enabled: "enabled",
    offset: "offset",
    is_notification_enabled:"is_notification_enabled",
  };
  const [DeviceData, setDeviceData] = useState({});
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [InstallationTime, setInstallationTime] = useState("");
  const [DeviceTime, setDeviceTime] = useState("Loading....");
  const [DeviceTime1, setDeviceTime1] = useState("Loading....");
  const [EditIntallationTime, setEditIntallationTime] = useState("");
  const [IsUTC, setIsUTC] = useState(false);
  const [userTimeZone,setUserTimeZone] = useState('')
  const [userLocalTime,setUserLocalTime] = useState('')
  const [dateTimeLoading,setDateTimeLoading] = useState(true)
  const [deviceTimeZoneLocale,setDeviceTimeZoneLocale] = useState('')
  const [deviceLocalTime,setDevicLocalTime]  = useState('')
  const [refreshData,setRefreshData] = useState(false);


  console.log("device time zone in the main page: ",userTimeZone);


  useEffect(() => {
    console.log("DEBUG: Timezone and Installation Time", {
      userTimeZone,
      InstallationTime,
      deviceTimeZoneLocale: userTimeZone // or whatever timezone you're using
    });
  
    const parseUTCTime = (timeStr) => {
      try {
        const [datePart, timePart] = timeStr.split(' ');
        const [day, month, year] = datePart.split('/');
        const isoFormattedTime = `${year}-${month}-${day}T${timePart}Z`;
        console.log("DEBUG: Parsed ISO Time", isoFormattedTime);
        return isoFormattedTime;
      } catch (error) {
        console.error("Error parsing UTC time:", error);
        return null;
      }
    };
  
    const convertToUserTimezone = (utcTimeStr, timeZone) => {
      try {
        // Validate inputs
        if (!utcTimeStr || !timeZone) {
          console.error("Invalid input to convertToUserTimezone", { utcTimeStr, timeZone });
          return null;
        }
  
        const isoTime = parseUTCTime(utcTimeStr);
        if (!isoTime) return null;
  
        // Add more detailed timezone conversion logging
        const convertedTime = dayjs(isoTime)
          .tz(timeZone)
          .format('DD/MM/YYYY HH:mm:ss');
        
        console.log("DEBUG: Converted Time", {
          originalTime: utcTimeStr,
          timezone: timeZone,
          convertedTime
        });
  
        return convertedTime;

      } catch (error) {
        console.error('Timezone conversion error:', {
          error: error.message,
          stack: error.stack,
          utcTimeStr,
          timeZone
        });
        return null;
      }
    };
  
    // Actual conversion
    const localTime = convertToUserTimezone(InstallationTime, userTimeZone);
  
    if (localTime) {
      setUserLocalTime(localTime);
    }
  
    // Device timezone conversion
    if (latitude && longitude) {
      const deviceTimeZone = tz_lookup(latitude, longitude);
      console.log("DEBUG: Device Timezone Lookup", {
        latitude, 
        longitude, 
        deviceTimeZone
      });
  
      const deviceTime = convertToUserTimezone(InstallationTime, deviceTimeZone);
      
      if (deviceTime) {
        setDeviceTimeZoneLocale(deviceTimeZone);
        setDevicLocalTime(deviceTime);
      }
    }
  
    setDateTimeLoading(false);
  }, [InstallationTime, latitude, longitude]);





  // Helper function to get the user's location and call the main function

  // const getLocalTimeFromUTCWithBrowserLocation = async (utcTimeString) => {
  //   return new Promise((resolve, reject) => {
  //     // Request the user's current location
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         const { latitude, longitude } = position.coords;

  //         try {
  //           // Call getDateTimeUsingLocation with the user's location
  //           const localTime = await getDateTimeUsingLocation(
  //             Number(latitude).toFixed(7),
  //             Number(longitude).toFixed(7),
  //             utcTimeString
  //           );
  //           console.log(
  //             "localTime OUTPUT",
  //             localTime,
  //             utcTimeString,
  //             latitude,
  //             longitude
  //           );
  //           resolve(localTime);
  //         } catch (error) {
  //           setIsUTC(true);
  //           resolve(utcTimeString);
  //         }
  //       },
  //       (error) => {
  //         setIsUTC(true);
  //         resolve(utcTimeString);
  //       }
  //     );
  //   });
  // };

  // const handleInstallationDateEdit = () => {
      
  //   console.log(EditIntallationTime)

  //   if (!EditIntallationTime) return;

  //   // Validate date format
  //   const dateRegex = /^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}$/;
  //   if (!dateRegex.test(EditIntallationTime)) {
  //     alert("Please use format: YYYY/MM/DD HH:mm:ss");
  //     return;
  //   }

  //   // Convert local time to UTC
  //   const date = new Date(EditIntallationTime.replace(/\//g, "-"));
  //   const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  //   // Format UTC date to required string format
  //   const formattedUTCDate = utcDate
  //     .toLocaleString("en-US", {
  //       timeZone: "UTC",
  //       hour12: false,
  //       year: "numeric",
  //       month: "2-digit",
  //       day: "2-digit",
  //       hour: "2-digit",
  //       minute: "2-digit",
  //       second: "2-digit",
  //     })
  //     .replace(",", "")
  //     .replace(/^(\d{2})\/(\d{2})\/(\d{4})(.*)$/, "$2/$1/$3$4");

  //     console.log("the formated utc date is ",formattedUTCDate)

  //   // Send to device and update state
  //   sendData(`${locationData.esp_id}/installation_date`, formattedUTCDate);
  //   setInstallationTime(formattedUTCDate);
  //   setEditIntallationTime("");
  // };
  
  useEffect(() => {
    // console.log()
    (async () => {
      console.log("TIME OUTPUT", InstallationTime, latitude, longitude);
    })();
  }, [latitude, longitude, InstallationTime]);

  useEffect(() => {
    if (locationData) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://api.db2sg.com/esps",
        headers: {},
      };
      axios
        .request(config)
        .then(async (response) => {
          let resData = response.data;
          if (resData.data) {
            let proData = resData?.data.filter(
              (el) => el.id == locationData.esp_id
            );
            setDeviceData(proData[0]);
            setAddress(proData[0].location);
            if (proData[0].latitude) {
              setAddress(
                await getAddressFromLatLng(
                  proData[0].latitude,
                  proData[0].longitude
                )
              );
            }
            setLatitude(proData[0].latitude);
            setLongitude(proData[0].longitude);

            setInstallationTime(proData[0].installation_date);

            proData[0]["enabled"] = locationData.user.devices[

              locationData.esp_id

            ].enabled
              ? 1
              : 0;

            let tempArr = configuration_list.map((el) => {
              setLocalState((prev) => ({
                ...prev,
                [proData[0].id + "/" + el]: proData[0][el],
              }));

              return proData[0].id + "/" + el;
            });
            client.subscribe(tempArr, (err) => {
              if (!err) {
              } else {
                // console.log(err);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      client.on("message", (topic, message) => {
        // message is Buffer
        setLocalState((prev) => ({ ...prev, [topic]: message.toString() }));

        if (topic === `${locationData.esp_id}/enabled`) {
          (async () => {
            const response = await axios.get(
              `https://api.db2sg.com/users/${locationData.user.id}/${
                locationData.esp_id
              }/${message.toString() === "1"}`
            );
          })();
        }
        console.log(topic);
        console.log(message.toString());
        // client.end();
      });
    }
    setRefreshData(false);
  }, [refreshData]);



  useEffect(() => {
    (async () => {
      if (latitude && longitude) {
        setAddress(await getAddressFromLatLng(latitude, longitude));
        sendData(`${locationData.esp_id}/latitude`, `${latitude}`);
        sendData(`${locationData.esp_id}/longitude`, `${longitude}`);
      }
    })();
  }, [latitude, locationData.esp_id, longitude]);

  // setInterval(() => setTimestamp(new Date().toLocaleString()), 1000);

  useEffect(() => {
    (async () => {
      // if (!isValidDate(InstallationTime)) {
      //   console.log("Invalid Date", InstallationTime);
      //   return;
      // }

      if (InstallationTime == "Invalid Date") {
        alert("Tried setting Invalid Date");
        return;
      }
      if (InstallationTime) {
        // setDeviceTime(
        //   await getDateTimeUsingLocation(latitude, longitude, InstallationTime)
        // );
      }
      sendData(
        `${locationData.esp_id}/installation_date`,
        // `${InstallationTime.replace(",", "")}`
      );
    })();
  }, [InstallationTime, locationData.esp_id]);

  // console.log("jello:::::::::::::;;;;")


 // tthis is the field for the data we want to edit and other cchanges

 const [value, setValue] = useState('');
 const [error, setError] = useState('');
 const [isValid, setIsValid] = useState(false);

 useEffect(() => {
   if (userLocalTime) {
     setValue(userLocalTime);
     validateDateTime(userLocalTime);
   }
 }, [userLocalTime]);

 const validateDateTime = (input) => {
   const regex = /^(\d{2})\/(\d{2})\/(\d{4})\s(\d{2}):(\d{2}):(\d{2})$/;
   const match = input.match(regex);
   
   if (!match) {
     setError('Please use format: DD/MM/YYYY HH:mm:ss');
     setIsValid(false);
     return false;
   }
   
   const [, day, month, year, hours, minutes, seconds] = match;
   const date = new Date(year, month - 1, day, hours, minutes, seconds);
   
   const isValidDate = 
     date.getDate() === parseInt(day) &&
     date.getMonth() === parseInt(month) - 1 &&
     date.getFullYear() === parseInt(year) &&
     date.getHours() === parseInt(hours) &&
     date.getMinutes() === parseInt(minutes) &&
     date.getSeconds() === parseInt(seconds);
     
   if (!isValidDate) {
     setError('Invalid date/time values');
     setIsValid(false);
     return false;
   }
   
   setError('');
   setIsValid(true);
   return true;
 };

 const handleChange = (e) => {
   const inputValue = e.target.value;
   setValue(inputValue);
   
   if (inputValue) {
     validateDateTime(inputValue);
   } else {
     setError('');
     setIsValid(false);
   }
 };


 //save button funconality very important 

 const convertToUTC = (localTimeStr) => {
  // Parse the input string
  const [datePart, timePart] = localTimeStr.split(' ');
  const [day, month, year] = datePart.split('/');
  const [hours, minutes, seconds] = timePart.split(':');
  
  // Create Date object in local time
  const localDate = new Date(year, month - 1, day, hours, minutes, seconds);
  
  // Get UTC components
  const utcYear = localDate.getUTCFullYear();
  const utcMonth = String(localDate.getUTCMonth() + 1).padStart(2, '0');
  const utcDay = String(localDate.getUTCDate()).padStart(2, '0');
  const utcHours = String(localDate.getUTCHours()).padStart(2, '0');
  const utcMinutes = String(localDate.getUTCMinutes()).padStart(2, '0');
  const utcSeconds = String(localDate.getUTCSeconds()).padStart(2, '0');
  
  // Format output
  return `${utcDay}/${utcMonth}/${utcYear} ${utcHours}:${utcMinutes}:${utcSeconds}`;
};

 const onSave = ()=>{
      console.log(value)
      const UTCFormat = convertToUTC(value);
      console.log("this the final fformat to save the data: ",UTCFormat);

      // console.log("locationdata for the id ",locationData.esp_id)

      sendData(
               `${locationData.esp_id}/installation_date`,
               `${UTCFormat}`
            );

            alert('changes had been made successfully!!')
             
            setRefreshData(true);
};

   
   console.log("the state of the filter:",localState[locationData.esp_id + "/" + initMap?.filter])

   const Offset = (localState[locationData.esp_id + "/" + initMap?.filter_led]== 1) ? parseFloat(localState[locationData.esp_id + "/" + initMap?.offset]) : 0;
    
   const Current = parseFloat(localState[locationData.esp_id + "/" + initMap?.current])

    var UpdateCurrent = (Current+Offset).toFixed(2).toString();

   console.log("sending the data to the backend ffor the updted offset current: ",UpdateCurrent);


   useEffect(()=>{
   
   },[Offset])

   console.log("$$$$$$$$$$$$$$$$$$",typeof(UpdateCurrent))


   console.log("################################## check the data of the notification",localState[locationData?.esp_id + "/"+initMap?.is_notification_enabled])

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "3fr 5fr",
        gridGap: "30px",
        mt: "20px",
        height: "444px",
      }}
    >
      <LoadingScreen open={loading} />
      <Box
        sx={{
          background: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          justifyItems: "center",
          //   justifyContent: "center",
        }}
        className="p-4"
      >
        <Box
          sx={{
            display: "grid",
            alignContent: "center",
            gridTemplateColumns: "2fr 5fr",
            // justifyContent: "space-around",
            alignItems: "center",
            // justifyItems: "center",
            mt: "20px",
            height: "40%",
          }}
        >
          <div className="mainbody">
            <div className="top"></div>
            <div className="bettrymain">
              <div
                className="charge"
                style={{
                  height: "95%",
                  marginTop: "5%",
                }}
              ></div>
            </div>
          </div>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr",
                gridGap: "20px",
                gap: "10px",
              }}
            >
              <Box>
                {" "}
                <FleshIcon />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1">Current: </Typography>
                <Typography variant="Body1"
                >
                  {" "}
                  {Current ? UpdateCurrent : '0'}

                  {/* {localState[locationData.esp_id + "/" + initMap?.current]} */}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr",
                gridGap: "20px",
                gap: "10px",
              }}
            >
              <Box>
                {" "}
                <ChangeTimeIcon />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1">Time left: </Typography>
                <Typography variant="Body1">
                  {Math.floor(
                    localState[locationData.esp_id + "/" + initMap?.time_left] /
                      60
                  )}
                  HH:
                  {localState[locationData.esp_id + "/" + initMap?.time_left] %
                    60}
                  MM
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {DeviceData.latitude && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
              padding: "5px",
              margin: "auto",
              borderRadius: "6px",
              width: "80%",
              mt: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "2px 10px 0px 10px",
                alignItems: "center",

                height: "200px",
              }}
            >
              <MapContainer
                lat={latitude}
                lng={longitude}
                maxHeight="200px"
                maxWidth="27%"
              />
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr",
            padding: "5px",
            background: "#E9F0F4",
            margin: "auto",
            borderRadius: "6px",
            width: "80%",
            mt: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "2px 10px 0px 10px",
              alignItems: "center",
              height: "70px",
            }}
          >
            <Box>
              {" "}
              <Typography variant="subtitle1"> Filter</Typography>{" "}
              <Typography variant="caption">
                PB Status:
                {localState[locationData.esp_id + "/" + initMap?.filter] == 1
                  ? "ON"
                  : "OFF"}
              </Typography>
            </Box>

            <Box>
              <Switch
                // {localState[locationData.esp_id + "/" + initMap?.time_left]}
                checked={
                  localState[locationData.esp_id + "/" + initMap?.filter] == 1
                    ? true
                    : false
                }
                onChange={(e) => {
                  // localState[locationData.esp_id + "/" + initMap?.filter]
                  sendData(
                    `${locationData.esp_id}/${initMap?.filter}`,
                    localState[locationData.esp_id + "/" + initMap?.filter] == 1
                      ? `0`
                      : `1`
                  );
                  // console.log(e.target.value);
                }}
                name="loading"
                color="primary"
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "2px 10px 0px 10px",
              alignItems: "center",

              height: "70px",
            }}
          >
            <Box>
              {" "}
              <Typography variant="subtitle1"> Enabled</Typography>{" "}
              <Typography variant="caption">
                Device Enabled:
                {localState[locationData.esp_id + "/" + initMap?.enabled] == 1
                  ? "Yes"
                  : "No"}
              </Typography>
            </Box>

            <Box>
              <Switch
                // {localState[locationData.esp_id + "/" + initMap?.time_left]}
                checked={
                  localState[locationData.esp_id + "/" + initMap?.enabled] == 1
                    ? true
                    : false
                }
                onChange={(e) => {
                  // localState[locationData.esp_id + "/" + initMap?.filter]
                  sendData(
                    `${locationData.esp_id}/${initMap?.enabled}`,
                    localState[locationData.esp_id + "/" + initMap?.enabled] ==
                      1
                      ? `0`
                      : `1`
                  );
                  setEnabled(
                    localState[locationData.esp_id + "/" + initMap?.enabled] !=
                      1
                  );
                  // console.log(e.target.value);
                }}
                name="loading"
                color="primary"
              />
            </Box>
          </Box>
          <Divider />
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "2px 10px 0px 10px",
              alignItems: "center",

              height: "70px",
            }}
          >
             <Box>
              {" "}
              <Typography variant="subtitle1"> Notification Enabled </Typography>{" "}
              <Typography variant="caption">
               Notification Status:
                {localState[locationData.esp_id + "/" + initMap?.is_notification_enabled] == 1
                  ? "ON"
                  : "OFF"}
              </Typography>
            </Box>

            <Box>
              <Switch
                // {localState[locationData.esp_id + "/" + initMap?.time_left]}
                checked={
                  localState[locationData.esp_id + "/" + initMap?.is_notification_enabled] == 1
                    ? true
                    : false
                }
                onChange={(e) => {
                  // localState[locationData.esp_id + "/" + initMap?.filter]
                  sendData(
                    `${locationData.esp_id}/${initMap?.is_notification_enabled}`,
                    localState[locationData.esp_id + "/" + initMap?.is_notification_enabled] == 1
                      ? `0`
                      : `1`
                  );
                   console.log("The obtained value is: ",e.target.value);
                }}
                name="loading"
                color="primary"
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "2px 10px 0px 10px",
              height: "70px",
              alignItems: "center",
            }}
          >
            <Box>
              {" "}
              <Typography variant="subtitle1"> Time on:</Typography>{" "}
              <Typography variant="caption">
                {" "}
                {localState[locationData.esp_id + "/" + initMap?.time_on] > 0
                  ? localState[locationData.esp_id + "/" + initMap?.time_on]
                  : 10}{" "}
                min(s)
              </Typography>
            </Box>
            <Box>
              <input
                type="range"
                min={1}
                max={20}
                value={
                  localState[locationData.esp_id + "/" + initMap?.time_on] > 0
                    ? localState[locationData.esp_id + "/" + initMap?.time_on]
                    : 10
                }
                onChange={(e) => {
                  sendData(
                    `${locationData.esp_id}/${initMap.time_on}`,
                    `${e.target.value}`
                  );
                  // console.log(e.target.value);
                }}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              margin: "2px 10px 0px 10px",
              height: "70px",
              alignItems: "left",
            }}
          >
            <Box className="pt-2">
              {" "}
              <Typography variant="subtitle1"> Low Threshold</Typography>{" "}
            </Box>
            <Box className="flex flex-row justify-between items-center">
              <Typography className="pr-4">0.1</Typography>
              <Slider
                value={
                  localState[locationData.esp_id + "/" + initMap?.low_thesold] >
                  0
                    ? localState[
                        locationData.esp_id + "/" + initMap?.low_thesold
                      ]
                    : 10
                }
                min={0.1}
                max={4}
                step={0.1}
                onChange={(e) => {
                  sendData(
                    `${locationData.esp_id}/${initMap.low_thesold}`,
                    `${e.target.value}`
                  );
                  // console.log(e.target.value);
                }}
                valueLabelDisplay="auto"
                style={{ width: "100%" }}
              />
              <Typography className="pl-4">4</Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              margin: "2px 10px 0px 10px",
              height: "70px",
              alignItems: "left",
            }}
          >
            <Box>
              {" "}
              <Typography variant="subtitle1"> High Threshold</Typography>{" "}
            </Box>
            <Box className="flex flex-row m-2 justify-between">
              <Typography className="pr-4">4</Typography>
              <Slider
                min={4}
                max={20}
                step={0.1}
                value={
                  localState[
                    locationData.esp_id + "/" + initMap?.high_thesold
                  ] > 0
                    ? localState[
                        locationData.esp_id + "/" + initMap?.high_thesold
                      ]
                    : 10
                }
                onChange={(e) => {
                  sendData(
                    `${locationData.esp_id}/${initMap.high_thesold}`,
                    `${e.target.value}`
                  );
                  // console.log(e.target.value);
                }}
                valueLabelDisplay="auto"
                style={{ width: "100%" }}
              />
              <Typography className="pl-5">20</Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              margin: "2px 10px 0px 10px",
              height: "70px",
              alignItems: "left",
            }}
          >
            <Box>
              {" "}
              <Typography variant="subtitle1"> Offset</Typography>{" "}
            </Box>
            <Box className="flex flex-row m-2 justify-between">
              <Typography className="pr-4">0</Typography>
              <Slider
                min={0}
                max={1}
                step={0.001}
                value={
                  localState[locationData.esp_id + "/" + initMap?.offset] !=
                  undefined
                    ? localState[locationData.esp_id + "/" + initMap?.offset]
                    : 0
                }
                onChange={(e) => {
                  sendData(
                    `${locationData.esp_id}/${initMap.offset}`,
                    `${e.target.value}`
                  );
                  // console.log(e.target.value);
                }}
                valueLabelDisplay="auto"
                style={{ width: "100%" }}
              />
              <Typography className="pl-5">1</Typography>
            </Box>
          </Box>
          <Divider />
        </Box>
        <Box
          sx={{
            height: 60,
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          background: "#FFFFFF",
          padding: "10px",
        }}
      >
        <DataList
          data={data}
          initMap={initMap}
          localState={localState}
          locationData={locationData}
          timestamp={locationData["user"]["timeZone"]}
        ></DataList>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2px 10px 0px 10px",
            alignItems: "center",
            height: "70px",
          }}
        >
          <Box>
            <Typography variant="subtitle1">Location</Typography>
            {address}
          </Box>
          <Box></Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2px 10px 0px 10px",
            alignItems: "center",
            height: "70px",
          }}
        >
          <Box>
            <Box className="h-4"></Box>

            <Typography variant="subtitle1">
              Installation Date & Time{" "}
              {IsUTC
                ? "UTC Time zone as no location access"
                : "(Your Time Zone - Taken From Your Browser)"}
            </Typography>
               
            <TextField
        value={dateTimeLoading ? "Loading..." : value}
        onChange={handleChange}
        placeholder="dd/mm/yyyy HH:mm:ss"
        fullWidth
        error={!!error}
        helperText={error}
        disabled={dateTimeLoading}
      />
             
            {/* <TextField
              value={dateTimeLoading?"loading...":userLocalTime}
              //  onChange={(e) => {
              //   const inputValue = e.target.value;
              //   // Initialize with current value if empty
              //   if (!EditIntallationTime) {
              //     setEditIntallationTime(InstallationTime);
              //      handleInstallationDateEdit()
              //   }
              //   // Update with new input
              //   setEditIntallationTime(inputValue);
              //    handleInstallationDateEdit()
              // }}
              placeholder="dd/mm/yyyy HH:mm:ss"
              // InputLabelProps={{
              //   shrink: true,
              // }}
              // inputProps={{
              //   pattern: "\\d{2}/\\d{2}/\\d{4} \\d{2}:\\d{2}:\\d{2}",
              //   title: "Format: DD/MM/YYYY HH:mm:ss",
              // }}
              fullWidth
            /> */}
             <LocationTime latitude={latitude} longitude={longitude} setUserTimeZone = {setUserTimeZone} setDeviceTimeZoneLocale={setDeviceTimeZoneLocale}/>
            {    

                <Button 
                 disabled={!isValid || dateTimeLoading}
                 onClick={onSave}
               className={`${!isValid ? 'bg-gray-300' : 'bg-blue-500'}`}
                  >
             SAVE
                </Button>
                
              // <Button
              //   onClick={() => {
              //     const convertToUTCFormat = (dateString) => {


              //      // console.log(first)
              //       return;
              //       // Parse the input date string parts
              //       const [datePart, timePart] = dateString.split(", ");
              //       const [month, day, year] = datePart.split("/");

              //       // Create date object using the parsed components
              //       const date = new Date(
              //         `${year}-${month}-${day} ${timePart}`
              //       );

              //       // Format the date to desired output
              //       return date.toLocaleString("en-US", {
              //         timeZone: "UTC",
              //         hour12: false,
              //         year: "numeric",
              //         month: "2-digit",
              //         day: "2-digit",
              //         hour: "2-digit",
              //         minute: "2-digit",
              //         second: "2-digit",
              //       });
              //     };

              //     const utcDate = convertToUTCFormat(EditIntallationTime);
              // //    const ourDate = utcDate.replace(",", "");

              //     sendData(
              //       `${locationData.esp_id}/installation_date`,
              //       // `${ourDate}`
              //     );

              //     // setInstallationTime(ourDate);
              //     //  handleInstallationDateEdit()
              //   }}
              // >
              //   {" "}
              //   SAVE{" "}
              // </Button>
            }
          </Box>
          <Box></Box>
        </Box>
        <Box className="h-8"></Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2px 10px 0px 10px",
            alignItems: "center",
            height: "70px",
          }}
        >
          <Box>
            <Typography variant="subtitle1">
              Installation Date & Time (Device Time Zone - { address }) 
            </Typography>
            <Typography>
            {deviceLocalTime}
            </Typography>
          </Box>
          <Box></Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2px 10px 0px 10px",
            alignItems: "center",
            height: "70px",
          }}
        >
          <Box>
            <Typography variant="subtitle1"> Total Work Time</Typography>
            {DeviceData.total_time} hours
          </Box>
          <Box>
            {" "}
            <Button
              onClick={() => {
                sendData(`${locationData.esp_id}/reset_total_time`, "0");
              }}
            >
              Reset
            </Button>{" "}
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2px 10px 0px 10px",
            alignItems: "center",
            height: "70px",
          }}
        >
          <Box>
            <Typography variant="subtitle1">
              {" "}
              Firmware Version Number
            </Typography>
            {DeviceData.version}
          </Box>
          <Box></Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2px 10px 0px 10px",
            alignItems: "center",
            height: "70px",
          }}
        >
          <Box>
            <Typography variant="subtitle1"> Longitude</Typography>
            <TextField
              value={longitude}
              onChange={(e) => {
                setLongitude(e.target.value);
              }}
            ></TextField>
          </Box>
        </Box>
        <Box height={20}></Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2px 10px 0px 10px",
            alignItems: "center",
            height: "70px",
          }}
        >
          <Box>
            <Typography variant="subtitle1"> Latitude</Typography>
            <TextField
              value={latitude}
              onChange={(e) => {
                setLatitude(e.target.value);
              }}
            ></TextField>
          </Box>
          <Box></Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2px 10px 0px 10px",
            alignItems: "center",
            height: "70px",
          }}
        >
          <Box>
            <Typography variant="subtitle1">Online Status</Typography>
          </Box>
          <OnlineIcon
            timestamp={localState[locationData.esp_id + "/" + "heartbeat"]}
          ></OnlineIcon>
        </Box>
        <Divider />
      </Box>
    </Box>
  );
}

const StatusIndicator = ({
  status,
  index,
  localState,
  locationData,
  initMap,
}) => {
  if (index === 0 || index === 5) return null;

  const getBackgroundColor = () => {
    const stateKey = `${locationData.esp_id}/${initMap[status]}`;
    const stateValue = +localState[stateKey];

    if (index === 1 && stateValue) return "green";
    if (index === 2 && stateValue) return "blue";
    if (index === 3 && stateValue) return "red";
    if (index === 4 && stateValue) return "#f3dc09";
    return "#FFFFFF";
  };

  return (
    <div
      style={{
        width: "35px",
        height: "35px",
        background: getBackgroundColor(),
        borderRadius: "50%",
      }}
    />
  );
};

const EditableField = ({ value, isEditing, onChange }) => {
  if (isEditing) {
    return (
      <TextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        size="small"
        fullWidth
      />
    );
  }
  return <Typography variant="body2">{value}</Typography>;
};

const DataItem = ({
  item,
  index,
  localState,
  locationData,
  initMap,
  timestamp,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableValue, setEditableValue] = useState(
    index === 0
      ? timestamp
      : index >= 5
      ? "HII"
      : +localState[`${locationData.esp_id}/${initMap[item.status]}`]
      ? "HIGH"
      : "LOW"
  );

  const handleEditToggle = () => {
    if (isEditing) {
      // Here you would typically update the value in your state or send it to a server
      console.log("New value:", editableValue);
    }
    setIsEditing(!isEditing);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "2px 10px 0px 10px",
          alignItems: "center",
          height: "70px",
        }}
      >
        <Box>
          <Typography variant="subtitle1">{item.name}</Typography>
          <EditableField
            value={editableValue}
            isEditing={isEditing}
            onChange={setEditableValue}
          />
        </Box>
        <Box>
          {item.editable ? (
            <Button onClick={handleEditToggle}>
              {isEditing ? "Done" : "Edit"}
            </Button>
          ) : (
            <StatusIndicator
              status={item.status}
              index={index}
              localState={localState}
              locationData={locationData}
              initMap={initMap}
            />
          )}
        </Box>
      
      </Box>
      <Divider />
    </>
  );
};

const DataList = ({ data, localState, locationData, initMap, timestamp }) => {
  return data.map((item, index) => (
    <DataItem
      key={item.name}
      item={item}
      index={index}
      localState={localState}
      locationData={locationData}
      initMap={initMap}
      timestamp={timestamp}
    />
  ));
};
